<template>
<div class="headerContainer">
    <div class="headerContainer_left"><span class="headerContainer_left_home"><img src="../assets/images/home.png" alt=""></span><span class="headerContainer_left_index"><router-link to="/">首页</router-link></span>
        <span class="headerContainer_left_arrow"><img src="../assets/images/right-arrow.png" alt=""></span><span class="headerContainer_left_title">{{title}}</span>
    </div>
    <div class="headerContainer_center"><span class="headerContainer_center_icon"><img :src=basicLogo alt=""></span><span class="headerContainer_center_title">{{basicTitle}}</span></div>
    <div class="headerContainer_right">
        <span class="headerContainer_right_icon"><img src="../assets/images/back-arrow.png" alt=""></span>
        <span class="headerContainer_right_title" @click="back">返回</span>
    </div>
  </div>
</template>

<script setup name="Header">
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
import {getBasicInfo} from "@/api/home"
import {ref} from "@vue/reactivity";
defineProps({title: String});
const router = useRouter();
const back = ()=>{
    router.go(-1)
}
const basicTitle =  ref("");
const basicLogo =  ref("https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/vol/20220718/1658154240755406.png");

const getInfo = async () => {
  let result = await getBasicInfo();
  if(result.data != null){
    if(result.data.stationName != null){
      basicTitle.value = result.data.stationName;
    }else {
      basicTitle.value = "山东阳光志愿者平台"
    }
    if(result.data.logo != null){
      basicLogo.value = result.data.logo;
    }
  }else {
    basicTitle.value = "山东阳光志愿者平台"
  }
}
getInfo();
</script>

<style lang="scss" scoped>
.headerContainer{
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 30px 24px 30px;
    &_left{
      display: flex;
      flex-direction: row;
      align-items: center;
        &_home{
          margin-right: 5px;
        }
        &_home img{
          height: 20px;

        }
        &_index{
          color: #C2C7CC;
          font-size: 20px;
          text-align: center;
        }
         &_index a{
           color: #C2C7CC;
          font-size: 20px;
          text-decoration: none;
          text-align: center;
         }
        &_arrow{


        }
        &_arrow img{
            vertical-align: middle;
          height: 20px;

        }
        &_title{
          color: #000000;
          font-size: 20px;
          text-align: center;
        }
    }
    &_left img{
        height: 20px;
    }
    &_center{
      display: flex;
      flex-direction: row;
      align-items: center;
      &_title{
        text-align: center;
        color: #212121;
        font-size: 20px;
        font-weight: bold;
      }
      &_icon{
        margin-right: 30px;
      }
      &_icon img{
         height: 30px;
      }
    }

    &_right{
      display: flex;
      flex-direction: row;
      align-items: center;
      &_icon{
        margin-right: 5px;
      }
      &_icon img{
        height: 20px;
        vertical-align: middle;
      }
      &_title{
        color: #FF9959;
        font-size: 20px;
      }
    }
}
</style>
