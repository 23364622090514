import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {navShow: false, keepAlive: true, title: '首页', isleftarrow: false,},
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {navShow: false, keepAlive: true, title: '登录', isleftarrow: false,},
        component: () => import(/* webpackChunkName: "home" */ '../views/login/Login.vue')
    },
    {
        path: '/putOut',
        name: 'PutOut',
        meta: {navShow: true, keepAlive: false, title: '发布活动', isleftarrow: false,},
        component: () => import( '../views/activity/PutOut.vue')
    },
    {
        path: '/newActivityList',
        name: 'newActivityList',
        meta: {navShow: true, keepAlive: false, title: '最新活动', isleftarrow: false,},
        component: () => import( '../views/activity/NewActivityList.vue')
    },
    {
        path: '/hotActivityList',
        name: 'HotActivityList',
        meta: {navShow: true, keepAlive: false, title: '热门活动', isleftarrow: false,},
        component: () => import( '../views/activity/HotActivityList.vue')
    },
    {
        path: '/activityTypeList',
        name: 'ActivityTypeList',
        meta: {navShow: true, keepAlive: false, title: '活动分类', isleftarrow: false,},
        component: () => import( '../views/activity/ActivityTypeList.vue')
    },
    {
        path: '/activityDetail',
        name: 'ActivityDetail',
        meta: {navShow: true, keepAlive: false, title: '活动详细', isleftarrow: false,},
        component: () => import( '../views/activity/ActivityDetail.vue')
    },
    {
        path: '/activityTypeList',
        name: 'ActivityTypeList',
        meta: {navShow: true, keepAlive: false, title: '活动分类', isleftarrow: false,},
        component: () => import( '../views/activity/ActivityTypeList.vue')
    },
    {
        path: '/claimActivityList',
        name: 'ClaimActivityList',
        meta: {navShow: true, keepAlive: false, title: '认领活动', isleftarrow: false,},
        component: () => import( '../views/activity/ClaimActivityList.vue')
    },
    {
        path: '/signedActivityList',
        name: 'SignedActivityList',
        meta: {navShow: true, keepAlive: false, title: '已报名', isleftarrow: false,},
        component: () => import( '../views/activity/SignedActivityList.vue')
    },
    {
        path: '/commentActivityList',
        name: 'CommentActivityList',
        meta: {navShow: true, keepAlive: false, title: '活动评价', isleftarrow: false,},
        component: () => import( '../views/activity/CommentActivityList.vue')
    },
    {
        path: '/announceDetail',
        name: 'AnnounceDetail',
        meta: {navShow: true, keepAlive: false, title: '公告详情', isleftarrow: false,},
        component: () => import( '../views/index/AnnounceDetail.vue')
    },
    {
        path: '/announceList',
        name: 'AnnounceList',
        meta: {navShow: true, keepAlive: false, title: '公告列表', isleftarrow: false,},
        component: () => import( '../views/index/AnnounceList.vue')
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/newsDetail',
        name: 'NewsDetail',
        meta: {navShow: true, keepAlive: false, title: '新闻详情', isleftarrow: false,},
        component: () => import( '../views/index/NewsDetail.vue')
    },
    {
        path: '/newsList',
        name: 'NewsList',
        meta: {navShow: true, keepAlive: false, title: '新闻列表', isleftarrow: false,},
        component: () => import( '../views/index/NewsList.vue')
    },
    {
        path: '/stations',
        name: 'Stations',
        meta: {navShow: true, keepAlive: false, title: '分站分布', isleftarrow: false,},
        component: () => import( '../views/index/Stations.vue')
    },
    {
        path: '/stationDetail',
        name: 'StationDetail',
        meta: {navShow: true, keepAlive: false, title: '分站详情', isleftarrow: false,},
        component: () => import( '../views/index/StationDetail.vue')
    },
    {
        path: '/searchCenter',
        name: 'SearchCenter',
        meta: {navShow: true, keepAlive: false, title: '志愿者查询', isleftarrow: false,},
        component: () => import( '../views/index/SearchCenter.vue')
    },
    {
        path: '/shopIndex',
        name: 'ShopIndex',
        meta: {navShow: true, keepAlive: false, title: '志愿者商城', isleftarrow: false,},
        component: () => import( '../views/shop/ShopIndex.vue')
    },
    {
        path: '/shopDetail',
        name: 'ShopDetail',
        meta: {navShow: true, keepAlive: false, title: '商品详情', isleftarrow: false,},
        component: () => import( '../views/shop/ShopDetail.vue')
    },
    {
        path: '/shopCart',
        name: 'ShopCart',
        meta: {navShow: true, keepAlive: false, title: '购物车', isleftarrow: false,},
        component: () => import( '../views/shop/ShopCart.vue')
    },
    {
        path: '/shopPay',
        name: 'ShopPay',
        meta: {navShow: true, keepAlive: false, title: '结算', isleftarrow: false,},
        component: () => import( '../views/shop/ShopPay.vue')
    },
    {
        path: '/memberCenter',
        name: 'MemberCenter',
        meta: {navShow: true, keepAlive: false, title: '会员中心', isleftarrow: false,},
        component: () => import( '../views/member/MemberCenter.vue')
    },
    {
        path: '/free',
        name: 'Free',
        meta: {navShow: true, keepAlive: false, title: '表单', isleftarrow: false,},
        component: () => import( '../views/free/Free.vue')
    },
    {
        path: '/actMap',
        name: 'ActMap',
        meta: {navShow: true, keepAlive: false, title: '服务地图', isleftarrow: false,},
        component: () => import( '../views/actmap/ActMap.vue')
    },
    {
        path: '/bindPhone',
        name: 'BindPhone',
        meta: {navShow: false, keepAlive: true, title: '绑定手机', isleftarrow: false,},
        component: () => import( '../views/login/BindPhone.vue')
    },
    {
        path: '/wxLogin',
        name: 'WxLogin',
        meta: {navShow: false, keepAlive: true, title: '确认授权', isleftarrow: false,},
        component: () => import( '../views/login/WxLogin.vue')
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
