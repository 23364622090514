'use strict'
// 跨域请求的地址
var domain = '/api'

// 服务端地址
//var target = 'http://localhost:9090/'
//var target = 'http://192.168.120.154:8080/'
// 正式环境
var target = 'https://apivol.ygsg.net/'
// 正式环境
var socketUrl = 'wss://apivol.ygsg.net/wss'
//var socketUrl = 'ws://localhost:9090/ws'

//前端页面的地址
//var fontUrl = 'https://localhost:9090/'
var fontUrl = 'https://pc.ygsg.net/'

module.exports = {
  domain: domain,
  target: target,
  fontUrl:fontUrl,
  socketUrl: socketUrl
}
