<template>
  <template v-if="navShow">
    <Header v-show="navShow" :title="title"></Header>
  </template>
<router-view v-slot="{ Component }" >
    <keep-alive :key="$route.path">
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
</router-view>
</template>
<script setup name="App">
import { ref } from '@vue/reactivity';
import { onMounted, watchEffect } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import Header from './components/Header.vue';
import {getBasicInfo} from "@/api/home"
const navShow = ref(false);
const title = ref("")
const route = useRoute()

onMounted(()=>{
  console.log(route.meta.navShow);
  navShow.value = route.meta.navShow
  title.value = route.meta.title
})
watchEffect(async ()=>{
  navShow.value = route.meta.navShow
  title.value = route.meta.title
  let result = await getBasicInfo();
  if(result.data != null){
    if(result.data.stationName != null){
      document.title = result.data.stationName;
    }else {
      document.title = "山东阳光志愿者平台"
    }
  }else {
    document.title = "山东阳光志愿者平台"
  }
})

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
